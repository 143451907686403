<template>
<div class="main">
  <div class="barontop"><h1>공유 에디터</h1></div>
    <Editor />
</div>
</template>

<script>
import Editor from './components/Editor.vue';
import 'codemirror/lib/codemirror.css';
import 'codemirror/theme/material-ocean.css';

export default {
  name: 'App',
  components: {
   Editor 
  },
}
</script>

<style>
body {
   background-color: #0F1119; 
   margin: 0;
   padding: 0;
}
#app {
    font-family: "Noto Sans KR", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #fff;
   margin: 0;
   padding: 0;
}

.main {
  /*display: flex;
  flex-direction: column;*/
}

.barontop {
  height: 60px;
  width: 100%;
  background-color: #0F1119; 
  position: fixed;
  box-shadow: 0 2px 10px rgba(78, 149, 255, 0.1);
  display: flex;
  justify-content: center;
  z-index: 99999;
}

.barontop h1 {
  margin: 0;
  line-height: 60px;
  font-size: 26px;
  color: #f07178;
  font-weight: 400;
}

.editor {
   height: 100%;
   width: 1024px;
   margin: auto;
   padding-top: 80px;
}
.vue-codemirror {
  height: 100%;
}

.CodeMirror {
  font-size: 18px !important;
  height: auto;
}
</style>