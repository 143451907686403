export default class GoClient {
    constructor(rev) {
        this.id = window.newClient({
            revision: rev,
            applyOperation: (op) => this.trigger('applyOperation', JSON.parse(op)),
            sendOperation: (op) => {
                const parsed = JSON.parse(op)
                this.trigger('sendOperation', parsed.rev, parsed.op)
            }
        })
    }

    applyServer(op) {
        window.applyServer(this.id, JSON.stringify(op))
    }

    applyClient(ops) {
        const jsonified = ops.map(op => JSON.stringify(op))
        const composed = window.compose(...jsonified)
        window.applyClient(this.id, composed)
    }

    serverAck() {
        window.serverAck(this.id)
    }

    registerCallbacks(cb) {
        this.callbacks = cb
    }

    trigger(event) {
        const args = Array.prototype.slice.call(arguments, 1);
        const action = this.callbacks && this.callbacks[event];
        if (action) { action.apply(this, args); }
    }

    close() {
        window.closeClient(this.id)
    }
}