export default class EditorClient  {
  constructor(client, editorAdapter, sendOperation) {
    this.client = client
    this.editorAdapter = editorAdapter

    this.client.registerCallbacks({
      sendOperation: (rev, op) => sendOperation(rev, op),
      applyOperation: (op) => this.editorAdapter.applyOperation(op)
    })

    this.editorAdapter.registerCallbacks({
      change: (ops) => this.client.applyClient(ops)
    })
  }

  handleOperation(op) {
    this.client.applyServer(op)
  }

  handleAck() {
    this.client.serverAck()
  }
}