function cmpPos(a, b) {
    if (a.line < b.line) { return -1; }
    if (a.line > b.line) { return 1; }
    if (a.ch < b.ch) { return -1; }
    if (a.ch > b.ch) { return 1; }
    return 0;
}
function posLe(a, b) { return cmpPos(a, b) <= 0; }

function codemirrorDocLength(doc) {
    return doc.indexFromPos({ line: doc.lastLine(), ch: 0 }) +
        doc.getLine(doc.lastLine()).length;
}

function operationsFromCodeMirrorChanges(changes, doc) {
    let docEndLength = codemirrorDocLength(doc);

    var indexFromPos = function (pos) {
        return doc.indexFromPos(pos);
    };

    function last(arr) { return arr[arr.length - 1]; }

    function sumLengths(strArr) {
        if (strArr.length === 0) { return 0; }
        var sum = 0;
        for (var i = 0; i < strArr.length; i++) { sum += strArr[i].length; }
        return sum + strArr.length - 1;
    }

    function updateIndexFromPos(indexFromPos, change) {
        return function (pos) {
            if (posLe(pos, change.from)) { return indexFromPos(pos); }
            if (posLe(change.to, pos)) {
                return indexFromPos({
                    line: pos.line + change.text.length - 1 - (change.to.line - change.from.line),
                    ch: (change.to.line < pos.line) ?
                        pos.ch :
                        (change.text.length <= 1) ?
                            pos.ch - (change.to.ch - change.from.ch) + sumLengths(change.text) :
                            pos.ch - change.to.ch + last(change.text).length
                }) + sumLengths(change.removed) - sumLengths(change.text);
            }
            if (change.from.line === pos.line) {
                return indexFromPos(change.from) + pos.ch - change.from.ch;
            }
            return indexFromPos(change.from) +
                sumLengths(change.removed.slice(0, pos.line - change.from.line)) +
                1 + pos.ch;
        };
    }

    let operations = []
    for (var i = changes.length - 1; i >= 0; i--) {
        var change = changes[i];
        indexFromPos = updateIndexFromPos(indexFromPos, change);

        var fromIndex = indexFromPos(change.from);
        var restLength = docEndLength - fromIndex - sumLengths(change.text);

        let opSet = []
        if (fromIndex > 0) {
            opSet.push({ type: 'retain', count: fromIndex })
        }

        const deleteCount = sumLengths(change.removed)
        if (deleteCount > 0) {
            opSet.push({ type: 'delete', count: sumLengths(change.removed) })
        }

        if (change.text.length > 0) {
            const text = change.text.join('\n')
            if (text.length > 0) {
                opSet.push({ type: 'insert', text: change.text.join('\n') })
            }
        }

        if (restLength > 0) {
            opSet.push({ type: 'retain', count: restLength })
        }

        operations.push(opSet)

        docEndLength += sumLengths(change.removed) - sumLengths(change.text);
    }

    return operations
}

export default class CodeMirrorAdapter {
    constructor(cm) {
        this.cm = cm
        this.ignoreNextChange = false
        this.changeInProgress = false

        this.cm.undo = () => { }
        this.cm.redo = () => { }
        this.cm.on('changes', (_, changes) => this.onChanges(changes))
    }

    registerCallbacks(cb) {
        this.callbacks = cb
    }

    onChanges(changes) {
        if (!this.ignoreNextChange) {
            var ops = operationsFromCodeMirrorChanges(changes, this.cm);
            this.trigger('change', ops);
        }
        this.changeInProgress = false;
        this.ignoreNextChange = false;
    }

    trigger(event) {
        var args = Array.prototype.slice.call(arguments, 1);
        var action = this.callbacks && this.callbacks[event];
        if (action) { action.apply(this, args); }
    }

    applyOperation(operation) {
        this.ignoreNextChange = true
        this.cm.operation(() => {
            let index = 0 // holds the current index into CodeMirror's content
            operation.forEach(op => {
                if (op.type === 'retain') {
                    index += op.count
                } else if (op.type === 'insert') {
                    this.cm.replaceRange(op.text, this.cm.posFromIndex(index));
                    index += op.text.length
                } else if (op.type === 'delete') {
                    const from = this.cm.posFromIndex(index)
                    const to = this.cm.posFromIndex(index + op.count)
                    this.cm.replaceRange('', from, to)
                }
            })
        })
    }
}