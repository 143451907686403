<template>
  <div class="editor">
    {{status}}
    <div class="vue-codemirror">
      <textarea
        ref="textarea"
        id="textarea"
      ></textarea>
    </div>
  </div>
</template>

<script>
import "codemirror/lib/codemirror.css";
import "codemirror/theme/material-ocean.css";
import 'codemirror/mode/go/go';

import CodeMirror from "codemirror";
//import OTJSClient from '../ot-adapters/otjsclient'
import GoClient from '../ot/goclient'
import CodeMirrorAdapter from "../ot/codemirror";
import EditorClient from "../ot/editorclient";
import { onMounted, ref } from 'vue';
import { v4 as uuidv4 } from 'uuid';


export default {
  name: "Editor",
  setup() {
    const status = ref('connecting...')
    const textarea = ref(null)

    onMounted(() => {
      let cminstance = undefined
      let goclient = undefined
      let socket = new WebSocket('wss://editor.learningspoons.danslee.com/ws')//'ws://localhost:8080')

      socket.addEventListener('open', () => {
        status.value = 'websocket opened'
      })

      socket.addEventListener('close', e => {
        status.value = 'websocket closed'
        if (cminstance !== undefined) {
          cminstance.toTextArea()
        }

        if (goclient !== undefined) {
          goclient.close()
        }
        console.log(e)
      })

      let editorClient
      let authorID = uuidv4()

      socket.addEventListener('message', e => {
        const data = JSON.parse(e.data)

        if (data.type === 'initMessage') {
          status.value = 'connected'
          cminstance = CodeMirror.fromTextArea(textarea.value, {
            tabSize: 4,
            mode: "text/x-go",
            theme: "material-ocean",
            lineNumbers: true,
            line: true,
            lineWrapping: true,
            smartIndent: false,
            indentUnit: 0,
            extraKeys: { Enter: cm => cm.replaceSelection("\n") }
          })
          cminstance.setValue(data.message.document)

          goclient = new GoClient(data.message.revision)
          editorClient = new EditorClient(
            //new OTJSClient(data.message.revision),
            goclient,
            new CodeMirrorAdapter(cminstance),
            (rev, op) => socket.send(JSON.stringify({
              authorID: authorID,
              op: op,
              revision: rev
            }))
          )

        } else if (data.type === 'opMessage') {
          if (data.message.authorID === authorID) {
            editorClient.handleAck()
          } else {
            editorClient.handleOperation(data.message.op)
          }
        }
      })
    })
    return {
      status,
      textarea
    }
  }
};
</script>

<style>
.editor {
  height: 100%;
}
.vue-codemirror {
  height: 100%;
}

.CodeMirror {
  font-size: 18px !important;
  height: auto;
}

.other-client {
  display: none;
}

#textarea {
  display: none;
}
</style>